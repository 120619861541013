import cx from 'classnames'

import { type SanityImageAndTextModule } from '@data/sanity/queries/types/modules'
import { backgroundColorClasses } from '@lib/color'

import ComplexPortableText from '@components/complex-portable-text'
import Photo from '@components/photo'

type ImageAndTextProps = Pick<
  SanityImageAndTextModule,
  'image' | 'textContent' | 'textPosition' | 'background'
>

const ImageAndText = ({
  image,
  textContent,
  textPosition,
  background,
}: ImageAndTextProps) => (
  <section className={cx(background ? backgroundColorClasses[background] : '')}>
    <div className="container py-10 md:py-20">
      <div className="grid md:grid-cols-2 items-center">
        {!!textContent && (
          <ComplexPortableText
            content={textContent}
            className={cx(
              `rc order-2 w-full max-w-[calc(var(--container-xl)/2)] mt-5 md:mt-0`,
              {
                'md:pr-[10%] md:ml-auto md:order-1': textPosition === 'left',
                'md:pl-[10%]': textPosition === 'right',
              }
            )}
          />
        )}

        {!!image && (
          <Photo
            image={image}
            fill
            className={cx('relative h-full order-1', {
              'md:order-2 md:pl-[10%]': textPosition === 'left',
              'md:pr-[10%]': textPosition === 'right',
            })}
            imageClassName="object-cover"
            style={{
              paddingTop: `${
                100 /
                (!!image.customRatio
                  ? image.customRatio
                  : image.asset.metadata.dimensions.aspectRatio)
              }%`,
            }}
          />
        )}
      </div>
    </div>
  </section>
)

export default ImageAndText
